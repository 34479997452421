<template>
    <a-modal
    v-model="visible"
    :footer="null"
    title="添加子账号"
    @cancel="onCancel"
    >
      <a-alert :style="{marginBottom: '15px'}" message="最多允许添加2个子账户" banner />
      <a-form
          class="mod-form"
          layout="horizontal"
          labelAlign="left"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          :colon="false"
          :form="form"
          @submit="onSubmit"
        >
          <a-form-item label="登录账号">
            <a-input

              placeholder="请输入账号"
              v-decorator="[
                'username',
                {
                  rules: [
                    {
                      validator: handleFieldValidate,
                    },
                  ],
                  validateTrigger: 'blur',
                },
              ]"
            >
            </a-input>
          </a-form-item>
          <a-form-item label="姓名">
            <a-input
              placeholder="请输入姓名"
              v-decorator="[
                'name',
                {
                  rules: [
                    {
                      validator: handleFieldValidate,
                    },
                  ],
                  validateTrigger: 'blur',
                },
              ]"
            >
            </a-input>
          </a-form-item>
          <a-form-item label="手机号">
            <a-input
              placeholder="请输入手机号码"
              v-decorator="[
                'mobile',
                {
                  rules: [
                    {
                      validator: handleFieldValidate,
                    },
                  ],
                  validateTrigger: 'blur',
                },
              ]"
            >
            </a-input>
          </a-form-item>
          <a-form-item label="密码">
            <a-input
              placeholder="请输入密码"
              type="password"
              v-decorator="[
                'password',
                {
                  rules: [
                    {
                      validator: handleFieldValidate,
                    },
                  ],
                  validateTrigger: 'blur',
                },
              ]"
            >
            </a-input>
          </a-form-item>
            <a-form-item label="备注">
            <a-input
              placeholder="备注内容"
              type="textarea"
              v-decorator="[
                'note',
                {
                  rules: [
                    {
                      validator: handleFieldValidate,
                    },
                  ],
                  validateTrigger: 'blur',
                },
              ]"
            >
            </a-input>
          </a-form-item>
          <a-form-item :wrapper-col="{span: 24}" class="form-item-center-footer">
              <a-button :loading="loading" type="primary" html-type="submit">保存</a-button>
              <a-button :loading="loading" @click="onCancel">取消</a-button>
          </a-form-item>
        </a-form>
    </a-modal>
</template>

<script>
import { addSubAccount } from '@/api/user'
import { validate } from '@/utils/validate'

export default {
  name: 'addSubAccount',
  data () {
    return {
      event: 0,
      labelCol: {
        span: 5
      },
      wrapperCol: {
        span: 19
      },
      form: this.$form.createForm(this),
      loading: false,
      visible: false
    }
  },
  methods: {
    handleFieldValidate (rule, value, callback) {
      validate(
        'sub-account',
        this.form.getFieldsValue(),
        rule.fullField
      ).then(({ valid, error }) => {
        if (valid) {
          callback()
        } else {
          callback(error)
        }
      })
    },
    onSubmit (e) {
      e.preventDefault()

      this.form.validateFields(async (errors, values) => {
        if (!errors) {
          try {
            this.loading = true
            await addSubAccount({
              username: values.username,
              realname: values.name,
              mobile: values.mobile,
              password: values.password,
              mark: values.note
            })

            this.$message.success('添加子账号成功', () => {
              this.loading = false
              this.visible = false
              this.$emit('change')
            })
          } catch (err) {
            this.loading = false
            this.$message.error(err.message)
          }
        }
      })
    },
    show () {
      this.visible = true
      this.form.resetFields()
    },
    onCancel () {
      this.visible = false
    }
  }
}
</script>

<style>

</style>
