<template>
  <a-config-provider :locale="local">
    <div class="order-wrap">
      <div class="order-title">管理子账号</div>
      <div class="order-main">
        <div class="order-form">
          <a-form layout="inline">
            <a-form-item label="登录账号">
              <a-input v-model="username" size="small" placeholder="子账号"></a-input>
            </a-form-item>
            <a-form-item label="姓名">
              <a-input v-model="realname" size="small" placeholder="姓名"></a-input>
            </a-form-item>
            <a-form-item label="状态">
              <a-select
                size="small"
                style="width: 100px"
                v-model="status"
              >
                <a-select-option value="">全部</a-select-option>
                <a-select-option value="0">已禁用</a-select-option>
                <a-select-option value="1">已启用</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item>
              <a-button size="small" type="primary" @click="onSearch"> 查询 </a-button>
              <a-button size="small" type="primary" ghost @click="showAddAccount"> 添加子账号 </a-button>
            </a-form-item>
          </a-form>
        </div>
        <div class="order-table">
          <a-table
            :columns="columns"
            :data-source="data"
            :pagination="pageOptions"
            :loading="loading"
            :rowKey="
              (record, index) => {
                return index;
              }
            "
            bordered
            @change="onChange"
          >
            <span slot="status" slot-scope="text">
              {{ text === 1 ? '已启用' : '已禁用' }}
            </span>
            <span slot="action" slot-scope="text, record">
              <a href="javascript: void(0);" @click="changeStatus(record)">{{ record.status === 1 ? '禁用' : '启用'  }}</a>
            </span>
          </a-table>
        </div>
      </div>
      <AddSubAccount ref="add" @change="onSuccess" />
    </div>
  </a-config-provider>
</template>

<script>
import { fetchSubAccount, changeStatus } from '@/api/user'
import tableBaseMixin from '@/common/mixin/table-base-mixin'
import AddSubAccount from './components/addSubAccount'

const columns = [
  {
    title: '登录账号',
    dataIndex: 'username'
  },
  {
    title: '姓名',
    dataIndex: 'realname'
  },
  {
    title: '状态',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' }
  },
  {
    title: '操作',
    dataIndex: 'id',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  name: 'subAccount',
  components: {
    AddSubAccount
  },
  mixins: [
    tableBaseMixin
  ],
  data () {
    return {
      status: '',
      username: '',
      realname: '',
      data: [],
      columns,
      loading: false,
      pageOptions: {
        current: 1,
        pageSize: 10,
        total: 1
      }
    }
  },
  created () {
    this.fetch()
  },
  methods: {
    async fetch () {
      try {
        const { username, status, realname, pageOptions } = this
        this.loading = true
        const data = await fetchSubAccount({
          username,
          status: status === '' ? status : status - 0,
          realname,
          size: pageOptions.pageSize,
          page: pageOptions.current - 1
        })

        this.data = data.content
        this.pageOptions.total = data.totalElements
        this.loading = false
      } catch (err) {
        this.loading = false
        this.$message.error(err.message)
      }
    },
    showAddAccount () {
      this.$refs.add.show()
    },
    onChange (pagination) {
      this.pageOptions = pagination
      this.fetch()
    },
    onSearch () {
      this.pageOptions.current = 1
      this.fetch()
    },
    changeStatus (record) {
      this.$confirm({
        content: `确定${record.status === 0 ? '启用' : '禁用'}子账号吗?`,
        onOk: async () => {
          try {
            await changeStatus({
              id: record.id,
              status: record.status
            })
            this.$message.success(`${record.status === 0 ? '启用' : '禁用'}子账号成功`, () => {
              this.fetch()
            })
          } catch (err) {
            this.$message.error(err.message)
          }
        }
      })
    },
    onSuccess () {
      this.pageOptions.current = 1
      this.fetch()
    }
  }
}
</script>

<style>

</style>
