var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"footer":null,"title":"添加子账号"},on:{"cancel":_vm.onCancel},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('a-alert',{style:({marginBottom: '15px'}),attrs:{"message":"最多允许添加2个子账户","banner":""}}),_c('a-form',{staticClass:"mod-form",attrs:{"layout":"horizontal","labelAlign":"left","label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"colon":false,"form":_vm.form},on:{"submit":_vm.onSubmit}},[_c('a-form-item',{attrs:{"label":"登录账号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'username',
            {
              rules: [
                {
                  validator: _vm.handleFieldValidate,
                } ],
              validateTrigger: 'blur',
            } ]),expression:"[\n            'username',\n            {\n              rules: [\n                {\n                  validator: handleFieldValidate,\n                },\n              ],\n              validateTrigger: 'blur',\n            },\n          ]"}],attrs:{"placeholder":"请输入账号"}})],1),_c('a-form-item',{attrs:{"label":"姓名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'name',
            {
              rules: [
                {
                  validator: _vm.handleFieldValidate,
                } ],
              validateTrigger: 'blur',
            } ]),expression:"[\n            'name',\n            {\n              rules: [\n                {\n                  validator: handleFieldValidate,\n                },\n              ],\n              validateTrigger: 'blur',\n            },\n          ]"}],attrs:{"placeholder":"请输入姓名"}})],1),_c('a-form-item',{attrs:{"label":"手机号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'mobile',
            {
              rules: [
                {
                  validator: _vm.handleFieldValidate,
                } ],
              validateTrigger: 'blur',
            } ]),expression:"[\n            'mobile',\n            {\n              rules: [\n                {\n                  validator: handleFieldValidate,\n                },\n              ],\n              validateTrigger: 'blur',\n            },\n          ]"}],attrs:{"placeholder":"请输入手机号码"}})],1),_c('a-form-item',{attrs:{"label":"密码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'password',
            {
              rules: [
                {
                  validator: _vm.handleFieldValidate,
                } ],
              validateTrigger: 'blur',
            } ]),expression:"[\n            'password',\n            {\n              rules: [\n                {\n                  validator: handleFieldValidate,\n                },\n              ],\n              validateTrigger: 'blur',\n            },\n          ]"}],attrs:{"placeholder":"请输入密码","type":"password"}})],1),_c('a-form-item',{attrs:{"label":"备注"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'note',
            {
              rules: [
                {
                  validator: _vm.handleFieldValidate,
                } ],
              validateTrigger: 'blur',
            } ]),expression:"[\n            'note',\n            {\n              rules: [\n                {\n                  validator: handleFieldValidate,\n                },\n              ],\n              validateTrigger: 'blur',\n            },\n          ]"}],attrs:{"placeholder":"备注内容","type":"textarea"}})],1),_c('a-form-item',{staticClass:"form-item-center-footer",attrs:{"wrapper-col":{span: 24}}},[_c('a-button',{attrs:{"loading":_vm.loading,"type":"primary","html-type":"submit"}},[_vm._v("保存")]),_c('a-button',{attrs:{"loading":_vm.loading},on:{"click":_vm.onCancel}},[_vm._v("取消")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }